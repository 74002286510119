import { AfterContentInit, Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";

import { DaniService } from 'src/app/services/getgenius/dani.service';
import { EventsService } from 'src/app/services/core/events.service';
import { LanguageService } from "src/app/services/core/language.service";
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ToolsService } from "src/app/services/utils/tools.service";
import { UserService } from 'src/app/services/core/user.service';

import { SpeechRecognition } from "@capacitor-community/speech-recognition";

import { SplineViewerComponent } from 'src/app/components/generic/spline/spline-viewer/spline-viewer.component';

@Component({
  selector: 'getgenius-dani',
  templateUrl: './getgenius-dani.component.html',
  styleUrls: ['./getgenius-dani.component.scss'],
})
export class GetgeniusDaniComponent implements AfterContentInit, OnDestroy, OnInit {
  @Input() config: any;
  @Input() state: any;

  @ViewChild('historyWrapper', { read: ElementRef }) private historyWrapper: ElementRef;
  @ViewChild('daniChatSettingsPopover') daniChatSettingsPopover: any;
  @ViewChild(SplineViewerComponent) splineViewer: any;

  isSettingsPopoverOpen: boolean = false;

  urlsByState: any = {};

  user: user;

  view: any = {
    addActions: [
      {
        icon: 'musical-notes-outline',
        label: 'audio',
        uid: 'audio',
      },
      {
        icon: 'document-text-outline',
        label: 'document',
        uid: 'document',
      },
      {
        icon: 'image-outline',
        label: 'image',
        uid: 'image',
      },
      {
        disabled: true,
        icon: 'film-outline',
        label: 'movie',
        uid: 'movie',
      },
    ],
    animationClass: 'full',
    chat: [],
    introCard: {
      uid: 'getgenius_dani_chat_top_card',
      text: 'getgenius_dani_chat_top_card_text',
      title: 'getgenius_dani_chat_top_card_title',
    },
    loadingOptions: {
    },
    splineOptions: {
      zoom: (window.outerWidth > 768 ? 0.65 : 0.35),
    },
  };

  webRecognition: any;

  webSpeechSynthesis: any;

  constructor(
    private aiTools: AiToolsService,

    private dani: DaniService,
    private events: EventsService,
    private language: LanguageService,
    private mediaService: MediaextendService,
    private navCtrl: NavController,
    private tools: ToolsService,
    private userService: UserService,
    private zone: NgZone,
  ) {
    this.urlsByState = this.dani.getUrlsByState();
    this.user = this.userService.getUser();
  }

  addFile(event: any = null) {
    this.daniChatSettingsPopover.event = event;
    this.isSettingsPopoverOpen = true;
  }

  public applyLoadingOptions() {
    if (!!this.config && !!this.config.zoom) {
      this.setZoom(this.config.zoom);
    }
  }

  audio() {
    return this.upload();
  }

  calcViewVars() {
    this.view.isDesktop = this.tools.isDesktop();

    this.state = this.state || {};
    this.state.hasMessages = !!(!!this.view.chat && !!this.view.chat.length);
    this.state.inConversation = !!(!!this.view.chat && !!this.view.chat.length && (this.view.chat.length > 1));

    this.view.splineOptions = this.view.splineOptions || {};
    this.view.splineOptions.zoom = (!!this.view.isDesktop ? 0.65 : 0.35);

    try {
      this.setSize(window.outerWidth, window.outerHeight);
    } catch (e) {
      console.warn('setting dani size failed', e);
    }

  }

  public async checkPermissions() {
    return new Promise((resolve, reject) => {
      if (this.tools.isWeb()) {
        try {

          const SpeechRecognition: any = this.getWebSpeechRecognition();
          //const SpeechGrammarList = (window as any).SpeechGrammarList || (window as any).webkitSpeechGrammarList;
          //const SpeechRecognitionEvent = (window as any).SpeechRecognitionEvent || (window as any).webkitSpeechRecognitionEvent;

          resolve(!!SpeechRecognition);
        } catch (e) {
          reject(e);
        }
      } else {
        SpeechRecognition.checkPermissions()
          .then((response: any) => {
            resolve(!!response && !!response.speechRecognition && (response.speechRecognition === 'granted'));
          })
          .catch(reject);
      }
    })
  }

  createTicket() {

    let inputs: any[] = [
      {
        icon: 'eye-outline',
        name: 'subject',
        placeholder: 'subject',
        type: 'text',
        uid: 'subject',
      },
      {
        icon: 'text-outline',
        name: 'input',
        placeholder: 'message',
        type: 'textarea',
        uid: 'input',
      },
      {
        icon: 'person-outline',
        name: 'name',
        placeholder: 'name',
        type: 'text',
        uid: 'name',
      },
      {
        icon: 'mail-outline',
        name: 'email',
        placeholder: 'your_email',
        type: 'email',
        uid: 'email',
      },
      {
        icon: 'call-outline',
        name: 'phone',
        placeholder: 'phone',
        type: 'tel',
        uid: 'phone',
      },
    ];

    inputs.forEach((input: any, index: number) => {

      setTimeout(() => {

        this.view.chat.push({
          icon: input.icon,
          mode: 'input',
          input: `${input.name}`,
          placeholder: `${input.placeholder}`,
          role: 'system',
          type: input.type || 'text',
          uid: `${input.uid}`,
        });

        setTimeout(() => {
          this.scrollDown();
        }, 100);

      }, (index * 200));

    });

    this.view.aiPrompt = '';
  }

  document() {
    return this.upload();
  }

  getLangCode() {
    const lang: string = this.userService.getLanguage();
    let langCode: string = `${lang}_${lang.toUpperCase()}`;

    if (langCode === 'en_EN') {
      langCode = 'en_US';
    }

    return langCode;
  }

  getWebSpeechRecognition() {
    return (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
  }

  handleUploadResponse(response: any) {
    console.log('handleUploadResponse', response);
  }

  image() {
    return this.upload();
  }

  initChat() {
    this.initEvents();

    if (!!this.config.hasChat) {

      if(!!this.config && !!this.config.chat) {
        this.view.chat = this.config.chat;
      }
      
      this.view.chat = this.view.chat || [];

      setTimeout(() => {
        
        if(!!this.config.userCanWrite && !this.view.chat.length) {
          this.view.chat.push({
            mode: 'view',
            input: `getgenius_dani_chat_intro_message`,
            role: 'assistant',
          });
        }

        this.calcViewVars();
      }, 250);
    }
  }

  initEvents() {

    this.events.subscribe('getgenius:dani:chat:add', (message: inboxChatMessage) => {

      this.view.chat.push({
        input: message.description,
      });

      this.view.animationClass = 'profile';
    });

    this.events.subscribe('getgenius:dani:spline:set', (url: string) => {
      this.setSplineUrl(url);
    });

  }

  initSpeechRecognition() {
    try {
      if (this.tools.isWeb()) {
        try {
          const SpeechRecognition: any = this.getWebSpeechRecognition();
          const langCode: string = this.getLangCode();

          this.webRecognition = new SpeechRecognition();
          //const speechRecognitionList = new SpeechGrammarList();
          //speechRecognitionList.addFromString(grammar, 1);
          //recognition.grammars = speechRecognitionList;
          this.webRecognition.continuous = false;
          this.webRecognition.lang = langCode;
          this.webRecognition.interimResults = false;
          this.webRecognition.maxAlternatives = 1;

          this.view.speechRecognitionAvailable = !!SpeechRecognition;

          if (!this.webSpeechSynthesis) {
            this.webSpeechSynthesis = window.speechSynthesis;
          }

          // in Google Chrome the voices are not ready on page load
          if ("onvoiceschanged" in this.webSpeechSynthesis) {
            this.webSpeechSynthesis.onvoiceschanged = () => {
              this.loadVoices();
            };
          } else {
            this.loadVoices();
          }

          this.view.webSpeechSynthesisAvailable = !!this.webSpeechSynthesis;

        } catch (e) {
          console.warn('speech web init failed', e);
          this.view.speechRecognitionAvailable = false;
        }
      } else {
        SpeechRecognition.available()
          .then((response: any) => {
            this.view.speechRecognitionAvailable = !!response && !!response.available;
          })
          .catch((e: any) => {
            console.warn('init speech recognition failed (2)', e);
          });
      }
    } catch (e) {
      console.warn('init speech recognition failed (1)', e);
    }
  }

  public listen() {

  }

  loadVoices() {

    const allVoices: any[] = this.webSpeechSynthesis.getVoices(),
      langCode: string = this.getLangCode().replace('_', '-'),
      allowedVoiceNames: string[] = ['Eddy', 'Flo', 'Reed', 'Martin', 'Rocko'];

    const voicesByLanguage = allVoices.filter((voice: any) => {
      return voice.lang === langCode;
    });

    const preferedVoices: any[] = voicesByLanguage.filter((voice: any) => {
      let bl: boolean = false;
      allowedVoiceNames.forEach((voicePart: string) => {
        bl = !!bl || (voice.name.indexOf(voicePart) !== -1);
      });
      return bl;
    });

    this.view.voices = (!!preferedVoices && !!preferedVoices.length ? preferedVoices : (!!voicesByLanguage && !!voicesByLanguage.length ? voicesByLanguage : allVoices));
  }

  movie() {
    return this.upload();
  }

  ngAfterContentInit() {
    this.initSpeechRecognition();
  }

  ngOnChanges() {
    this.applyLoadingOptions();
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.initChat();
    this.applyLoadingOptions();
  }

  @HostListener('window:resize')
  onResize() {
    this.calcViewVars();
  }

  public play() {
    return this.splineViewer.play();
  }

  public async record() {

    if (!!this.tools.isWeb()) {

      try {
        this.webSpeechSynthesis.cancel();
      } catch (e) {
      }

      // start listening
      this.webRecognition.start();
      this.view.listening = true;

      // on error
      this.webRecognition.onerror = (event) => {
        console.error('speech recognition error', event);
      };

      // handle no match
      this.webRecognition.onnomatch = (event) => {
        console.log('no match', event);
      };

      // on input update, set response to chat message textarea
      this.webRecognition.onresult = (event) => {
        if (!!event && !!event.results && event.results[0] && event.results[0][0] && event.results[0][0].transcript) {
          this.view.aiPrompt = event.results[0][0].transcript;
        }
      };

      // on speech end, stop listening and send input
      this.webRecognition.onspeechend = () => {
        this.webRecognition.stop();
        this.view.listening = false;

        setTimeout(() => {
          if (!!this.view.aiPrompt) {
            this.runAiPrompt();
          }
        }, 100);
      };

      return false;
    }

    let check: any = false;

    try {
      check = await this.checkPermissions();
    } catch (e) {
      check = false;
    }

    if (!check) {

      try {
        SpeechRecognition.requestPermissions();
      } catch (e) {
        this.events.publish('error', e);
      }

      return false;
    }

    try {

      SpeechRecognition.start({
        language: `${this.language.getCurrentLanguage() || 'en'}-${this.language.getCurrentLanguage() || 'en'}`,
        maxResults: 2,
        prompt: "Say something",
        partialResults: true,
        popup: true,
      });

      SpeechRecognition.removeAllListeners();

      SpeechRecognition.addListener("partialResults", (data: any) => {
        console.log("partialResults was fired", data.matches);
        this.view.aiPrompt = (!!data.matches && !!data.matches[0] ? data.matches[0] : '');
      });

      this.view.listening = true;

    } catch (e) {
      this.events.publish('error', e);
      this.view.listening = false;
    }
  }

  public resend(message: any) {
    this.view.aiPrompt = `${message.input || ''}`;
    this.runAiPrompt();
  }

  public runAiPrompt(options: any = {}) {

    if (!!this.view.blockRunAiPrompt) {
      return false;
    }

    this.view.blockRunAiPrompt = true;

    setTimeout(() => {
      this.view.blockRunAiPrompt = false;
    }, 500);

    this.view.chat = this.view.chat || [];
    this.view.iTry = (!!options && !!options.iTry ? options.iTry : 0);
    this.view.loading = true;

    this.setSplineUrl(this.urlsByState.WORKING);

    setTimeout(() => {
      this.scrollDown();

      /*
      if (this.tools.isDesktop()) {
        this.setZoom(0.5);
      } else {
        this.setZoom(0.5);
      }
      */
    }, 100);

    let history: any[] = [];

    // add input to chat
    if (!!this.view.aiPrompt) {
      this.view.chat.push({
        input: `${this.view.aiPrompt}`,
        role: 'user',
        mode: 'view',
      });
    }

    // calculate history
    if (!!this.view.chat && !!this.view.chat.length) {
      history = this.view.chat.map((historyItem: any) => {
        return {
          input: `${historyItem.post_content || historyItem.input}`,
          role: (historyItem.role || 'user'),
          mode: historyItem.mode || 'view',
        };
      });
    }

    let aiParams: any = {
      history: history,
      post_content: `${this.view.aiPrompt}`,
    };

    this.calcViewVars();

    this.dani.sendChat(aiParams, false, this.config || {}, {
      useFunctions: true,
    })
      .then((response: any) => {
        this.view.loading = false;

        this.setZoom(0.65);
        this.setSplineUrl(this.urlsByState.IDLE);

        let checkFunctionCall = this.aiTools.hasFunctionCallResponse(response);
        let blHasFunctionCall: boolean = !!(!!checkFunctionCall && !!checkFunctionCall.match);

        if (blHasFunctionCall && !!checkFunctionCall.details) {

          switch (checkFunctionCall.details.name) {
            case 'create_ticket':
              this.createTicket();
              break;
            default:
              this.aiTools.executeFunctionCall(response)
                .then((functionCallResponse: any) => {
                  this.view.aiPrompt = '';
                })
                .catch((error: any) => {
                  console.warn('executing function call failed', error);
                });
              break;
          }

        } else
          if (!!response && !!response.output) {
            let split: string[] = this.tools.splitTripleBackticksWithCodeBlocks(`${response.output}`);

            this.view.chat = history;

            split.forEach((part: string, splitIndex: number) => {
              setTimeout(() => {
                if (!!part) {
                  this.zone.run(() => {
                    this.view.chat.push({
                      mode: 'view',
                      input: `${this.tools.nl2br(part)}`,
                      role: 'assistant',
                    });

                    setTimeout(async () => {
                      this.speakText(part);

                      setTimeout(async () => {
                        this.scrollDown();
                      }, 100);
                    });
                  });
                }
              }, (splitIndex) * 300);
            });

            this.view.aiPrompt = '';
            this.calcViewVars();
          }

        setTimeout(() => {
          this.scrollDown();
        }, 200);

      })
      .catch((error: any) => {
        this.view.loading = false;
        this.setSplineUrl(this.urlsByState.IDLE);

        if (!!error && (error === 'Error_pipeline_ai_resource_busy') && (3 > this.view.iTry)) {
          setTimeout(() => {
            this.runAiPrompt({
              iTry: ((this.view.iTry || 0) + 1),
            });
          }, 500);
        } else {
          this.events.publish('error', error);
        }
      });
  }

  async runItemSelectionOption(item: any, option: any) {
    try {

      if (!option || !option.uid) {
        return false;
      }

      const exec: any = await this[option.uid](item);

      this.isSettingsPopoverOpen = false;

    } catch (e) {
      console.warn('executing single selection on item failed', e);
      this.events.publish('error', e);
    }
  }

  public scrollDown() {
    try {
      this.historyWrapper.nativeElement.scrollTop = this.historyWrapper.nativeElement.scrollHeight;
    } catch (e) {
      console.warn('scroll down failed', e);
    }
  }

  public send() {

    try {
      this.stopListening();
    } catch (e) {
      console.warn('stopping to listen failed', e);
    }

    this.runAiPrompt();
  }

  public setChat(chat: any[]) {
    this.view.chat = (chat || []);

    setTimeout(() => {
      this.calcViewVars();
      this.scrollDown();
    });
  }

  public async setSplineUrl(url: string) {
    try {

      this.view.splineOptions = {
        ...this.view.splineOptions,
        path: url,
      };

      if (!this.splineViewer) {
        return false;
      }

      await this.splineViewer.updateAnimation(this.view.splineOptions);

    } catch (e) {
      console.warn('setting url failed', e);
    }
  }

  public setSize(width: number, height: number) {
    return this.splineViewer.setSize(width, height);
  }

  public setZoom(zoom: number) {

    this.view.splineOptions = {
      ...this.view.splineOptions,
      zoom: zoom,
    };

    if (!this.splineViewer) {
      return false;
    }

    return this.splineViewer.setZoom(zoom);
  }

  speakText(text: string) {

    if (!text || (!!this.config && !this.config.allowSpeak)) {
      return false;
    }

    if (!!this.webSpeechSynthesis) {
      //this.webSpeechSynthesis.speak(text);

      const utterThis = new SpeechSynthesisUtterance(text);

      if (!!this.view.voices && !!this.view.voices[0]) {
        utterThis.voice = this.view.voices[0];
      }

      this.webSpeechSynthesis.speak(utterThis);
    }
  }

  public stop() {

    if (!this.splineViewer) {
      return false;
    }

    return this.splineViewer.stop();
  }

  public stopListening() {
    this.view.listening = false;

    try {
      SpeechRecognition.stop();
    } catch (e) {
      console.warn('stopping failed', e);
    }

    this.runAiPrompt();
  }

  toChatPage() {
    this.navCtrl.navigateForward('/dani/chat');
  }

  toggleSpeak() {
    this.view.shouldSpeak = !this.view.shouldSpeak;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  upload(params: any = {}) {
    this.mediaService.applyFromWeb(null, params)
      .then((response: any) => {
        this.handleUploadResponse(response);
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

}